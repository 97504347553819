export const serviceTimeHoursConstant = [{
    text: "30 mins",
    value: 1
}, {
    text: "1 hr",
    value: 2
}, {
    text: "1 hr 30 mins",
    value: 3
}, {
    text: "2 hrs",
    value: 4
}, {
    text: "2 hrs 30 mins",
    value: 5
}, {
    text: "3 hrs",
    value: 6
}, {
    text: "3 hrs 30 mins",
    value: 7
}, {
    text: "4 hrs",
    value: 8
}, {
    text: "4 hrs 30 mins",
    value: 9
}, {
    text: "5 hrs",
    value: 10
}, {
    text: "5 hrs 30 mins",
    value: 11
}, {
    text: "6 hrs",
    value: 12
}, {
    text: "6 hrs 30 mins",
    value: 13
}, {
    text: "7 hrs",
    value: 14
}, {
    text: "7 hrs 30 mins",
    value: 15
}, {
    text: "8 hrs",
    value: 16
}, {
    text: "8 hrs 30 mins",
    value: 17
}, {
    text: "9 hrs",
    value: 18
}, {
    text: "9 hrs 30 mins",
    value: 19
}, {
    text: "10 hrs",
    value: 20
}, {
    text: "10 hrs 30 mins",
    value: 21
}, {
    text: "11 hrs",
    value: 22
}, {
    text: "11 hrs 30 mins",
    value: 23
}, {
    text: "12 hrs",
    value: 24
}, {
    text: "12 hrs 30 mins",
    value: 25
}, {
    text: "13 hrs",
    value: 26
}, {
    text: "13 hrs 30 mins",
    value: 27
}, {
    text: "14 hrs",
    value: 28
}, {
    text: "14 hrs 30 mins",
    value: 29
}, {
    text: "15 hrs",
    value: 30
}, {
    text: "15 hrs 30 mins",
    value: 31
}, {
    text: "16 hrs",
    value: 32
}, {
    text: "16 hrs 30 mins",
    value: 33
}, {
    text: "17 hrs",
    value: 34
}, {
    text: "17 hrs 30 mins",
    value: 35
}, {
    text: "18 hrs",
    value: 36
}, {
    text: "18 hrs 30 mins",
    value: 37
}, {
    text: "19 hrs",
    value: 38
}, {
    text: "19 hrs 30 mins",
    value: 39
}, {
    text: "20 hrs",
    value: 40
}, {
    text: "20 hrs 30 mins",
    value: 41
}, {
    text: "21 hrs",
    value: 42
}, {
    text: "21 hrs 30 mins",
    value: 43
}, {
    text: "22 hrs",
    value: 44
}, {
    text: "22 hrs 30 mins",
    value: 45
}, {
    text: "23 hrs",
    value: 46
}, {
    text: "23 hrs 30 mins",
    value: 47
}, {
    text: "24 hrs",
    value: 48
}];

export const timeArrConst = ["12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];

export const daysConstant = [{
    day: 1,
    text: "Monday",
    selected: false
},{
    day: 2,
    text: "Tuesday",
    selected: false
},{
    day: 3,
    text: "Wednesday",
    selected: false
},{
    day: 4,
    text: "Thursday",
    selected: false
},{
    day: 5,
    text: "Friday",
    selected: false
},{
    day: 6,
    text: "Saturday",
    selected: false
},{
    day: 7,
    text: "Sunday",
    selected: false
}];