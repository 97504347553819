import React, {useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import {toast} from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import {removeSubAdmin} from '../../../crud/sub-admin.crud';
import {injectIntl} from "react-intl";
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import {connect} from "react-redux";
import * as subadmin from "../../../store/ducks/sub-admin.duck";
const RemoveSubAdmin = (props) => {
    console.log(props);
    const {classes, intl} = props;
    const [loading,
        setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true);
        removeSubAdmin(props.subAdmin._id).then((output) => {
            if (output.data.success) {
                props.subAdminUpdated({skip: 0, limit: 5});
                setLoading(false);
                toast.success('Subadmin is successfully deleted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                props.handleClose();
            }
        }).catch((error) => {
            console.log('error', error);
            setLoading(false);
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                toast.error(intl.formatMessage({
                    id: ApiErrorMessage[error.response.data.errorCode]
                }, {title: props.subAdmin.firstName}), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });

    };

    return (
        <React.Fragment>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.subAdmin.personalInfo.firstName} {props.subAdmin.personalInfo.lastName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this subadmin?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        No
                    </Button>

                    <Button
                        variant="primary"
                        style={{
                        position: "relative"
                    }}
                        onClick={() => handleDelete(props.subAdmin._id)}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
  return {data: state};
};

const mapDispatchToProps = dispatch => {
  return {
      subAdminUpdated: ({skip, limit}) => dispatch(subadmin.actions.subAdminUpdated({skip, limit}))
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
  ...styles(theme)
}), {withTheme: true})(RemoveSubAdmin)));
