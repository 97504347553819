import React from 'react';

import {withStyles} from "@material-ui/core/styles";
// import "./service.css";

const useStyles = (theme) => ({
    show: {
        display: 'block!important',
        background: 'rgba(0,0,0,0.5)!important'
    } 
});

const RemoveModal = (props) => {
    const classes = props.classes;
    return (
        <div className="row" style={{margin:0}}>
            <div className={`modal ${props.displayRemoveModal? classes.show: null}`} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Remove {props.removeType}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{margin: 0}}>
                                <h5 className="col-12 p-0 mb-3" style={{color: "#000"}}>
                                    Are you sure you want to remove the {props.removeType ? props.removeType.toLowerCase() : ""}?
                                </h5>
                            </div>
                              
                            <div className="col-12 p-0 text-right mt-3">
                              <button type="button" className="btn btn-outline-primary mr-2" onClick={() => props.openRemoveModal(false)}>Cancel</button>
                              <button type="button" className="btn btn-danger" onClick={props.removeData}>Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default withStyles((theme) => ({
    ...useStyles(theme)
}), {withTheme: true})(RemoveModal);