import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { addSubAdmin } from "../../../crud/sub-admin.crud";
import { listRole } from "../../../crud/role.crud";
import * as subAdmin from "../../../store/ducks/sub-admin.duck";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import { Grid } from "@material-ui/core";
const CreateSubAdmin = (props) => {
  const { classes, intl } = props;
  const [errors, setErrors] = useState({
    fname: null,
    lname: null,
    email: null,
  });
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  // const [isSuperAdmin,
  //     setIsSuperAdmin] = useState(false);

  const isSuperAdmin = false;

  useEffect(() => {
    listRole({ skip: 0, limit: 0 })
      .then((output) => {
        if (output.data.success) {
          setRoles(output.data.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          toast.error(
            intl.formatMessage(
              {
                id: ApiErrorMessage[error.response.data.errorCode],
              },
              { title: fname }
            ),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFNameHandler = (event) => {
    setFName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        fname: "First Name is required",
      });
    } else {
      setErrors({
        ...errors,
        fname: null,
      });
    }
  };

  const onChangeLNameHandler = (event) => {
    setLName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        lname: "Last Name is required",
      });
    } else {
      setErrors({
        ...errors,
        lname: null,
      });
    }
  };

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
    } else {
      setErrors({
        ...errors,
        email: null,
      });
    }
  };

  const resetState = () => {
    setFName("");
    setLName("");
    setEmail("");
    setErrors({ fname: null, lname: null, email: null });
  };

  const validForm = () => {
    let e = true;

    if (!fname && !lname && !email) {
      setErrors({
        ...errors,
        fname: "First Name is required",
        lname: "Last Name is required",
        email: "Profile Picture is required",
      });
      e = false;
    } else if (!fname) {
      setErrors({
        ...errors,
        fname: "First Name is required",
      });
      e = false;
    } else if (!lname) {
      setErrors({
        ...errors,
        lname: "Last Name is required",
      });
      e = false;
    } else if (!email) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        fname: null,
        lname: null,
        email: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      const payload = {
        personalInfo: {
          firstName: fname,
          lastName: lname,
          email: email,
        },
        roleInfo: {
          isSuperAdmin: isSuperAdmin,
          roleId: selectedRole,
        },
      };
      addSubAdmin(payload)
        .then((output) => {
          if (output.data.success) {
            props.subAdminAdded({ skip: 0, limit: 5 });
            resetState();
            setLoading(false);
            toast.success("Subadmin is successfully added!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("subAdmin");
          } else {
            setLoading(false);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[output.data.errorCode],
                },
                { title: fname }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[error.response.data.errorCode],
                },
                { title: fname }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  };

  // const handleCheckChange = (event) => {
  //     setIsSuperAdmin(event.target.checked);
  // };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          resetState();
          props.handleClose("subAdmin");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subadmin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="subadmin.name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={fname}
                onChange={onChangeFNameHandler}
                isInvalid={!!errors.fname}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fname}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="subadmin.name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lname}
                onChange={onChangeLNameHandler}
                isInvalid={!!errors.lname}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lname}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="subadmin.email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={onChangeEmailHandler}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Grid container>
              {/* <Grid item xs>
                                <FormControlLabel
                                    control={< Checkbox checked = {
                                    isSuperAdmin
                                }
                                onChange = {
                                    handleCheckChange
                                }
                                color = "primary" />}
                                    label={`Super Access`}/>
                            </Grid> */}
              <Grid item xs>
                {!isSuperAdmin && roles.length ? (
                  <FormControl
                    style={{
                      minWidth: 220,
                    }}
                  >
                    <InputLabel htmlFor="role">Select Role</InputLabel>

                    <Select
                      autoWidth
                      value={selectedRole}
                      onChange={(e) => {
                        setSelectedRole(e.target.value);
                      }}
                      inputProps={{
                        name: "role",
                      }}
                    >
                      {roles.length
                        ? roles.map((eachR, keyR) => (
                            <MenuItem key={keyR} value={eachR._id}>
                              {eachR.name}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                ) : null}
              </Grid>
            </Grid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              resetState();
              props.handleClose("subAdmin");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    subAdminAdded: ({ skip, limit }) =>
      dispatch(subAdmin.actions.subAdminAdded({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(CreateSubAdmin)
  )
);
