import {listFaq} from "../../crud/faq.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    FAQ_ADDED: "FAQ_ADDED",
    FAQ_ADDED_SUCCESS: "FAQ_ADDED_SUCCESS",
    FAQ_UPDATED: "FAQ_UPDATED",
    FAQ_UPDATED_SUCCESS: "FAQ_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.FAQ_ADDED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        case actionTypes.FAQ_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
    faqAdded: ({skip, limit}) => ({
        type: actionTypes.FAQ_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    faqUpdated: ({skip, limit}) => ({
        type: actionTypes.FAQ_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {
    yield takeLatest(actionTypes.FAQ_ADDED, function * faqAdded(action) {
        const {skip, limit} = action.payload;
        const listData = yield listFaq({skip, limit});

        yield put({type: actionTypes.FAQ_ADDED_SUCCESS, payload: {
                listData
            }});
    });

    yield takeLatest(actionTypes.FAQ_UPDATED, function * faqUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listFaq({skip, limit});

        yield put({type: actionTypes.FAQ_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
