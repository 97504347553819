const ModuleConfig = {
    'dashboard': {
        key:'dashboard',
        name: 'Dashboard',
        checked: false, 
        role: 1
    },
    'organizations': {
        key:'organizations',
        name: 'Organization', 
        checked: false, 
        role: 1
    },
    'professionalCategories': {
        key:'professionalCategories',
        name: 'Professional Category', 
        checked: false, 
        role: 1
    },
    'professionals': {
        key:'professionals',
        name: 'Professional', 
        checked: false, 
        role: 1
    },
    'languages': {
        key:'languages',
        name: 'Language', 
        checked: false, 
        role: 1
    },
    'problemCategories': {
        key:'problemCategories',
        name: 'Problem Category', 
        checked: false, 
        role: 1
    },
    'problems': {
        key:'problems',
        name: 'Problem', 
        checked: false, 
        role: 1
    },
    'users': {
        key:'users',
        name: 'User Management', 
        checked: false, 
        role: 1
    },
    'bookings': {
        key:'bookings',
        name: 'Booking', 
        checked: false, 
        role: 1
    },
    'payments': {
        key:'payments',
        name: 'Payment', 
        checked: false, 
        role: 1
    },
    'forums': {
        key:'forums',
        name: 'Forum', 
        checked: false, 
        role: 1
    },
    'faqs': {
        key:'faqs',
        name: 'FAQ', 
        checked: false, 
        role: 1
    },
    'supports': {
        key:'supports',
        name: 'Support', 
        checked: false, 
        role: 1
    },
    'globalSetting': {
        key:'globalSetting',
        name: 'Global Settings', 
        checked: false, 
        role: 1
    },
};

export default ModuleConfig;