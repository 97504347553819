import React, { useEffect, useState, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import LayoutConfig from "../../../../_metronic/layout/LayoutConfig";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  supportDetails,
  approveSupportDetails,
  rejectSupportDetails,
} from "../../../crud/support.crud";
import supportConfig from "../../../config/support";
import { green } from "@material-ui/core/colors";

const useStyles = (theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: theme.spacing(1),
  },
  flexBetween: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dangerBtn: {
    backgroundColor: LayoutConfig.colors.state.danger,
    color: "white",
    "&:hover": {
      backgroundColor: LayoutConfig.colors.state.danger,
      color: "white",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const SupportDetails = (props) => {
  const { supportId } = useParams();
  const classes = props.classes;
  const [supportDetailsInfo, setSupportDetailsInfo] = useState();
  useEffect(() => {
    supportDetails(supportId).then((output) => {
      setSupportDetailsInfo({
        ...supportDetailsInfo,
        ...output.data.data,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approveSupport = (supportId) => {
    approveSupportDetails(supportId).then((output) => {
      setSupportDetailsInfo({
        ...supportDetailsInfo,
        ...output.data.data,
      });
    });
  };

  const rejectSupport = (supportId) => {
    rejectSupportDetails(supportId).then((output) => {
      setSupportDetailsInfo({
        ...supportDetailsInfo,
        ...output.data.data,
      });
    });
  };

  if (!supportDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      {/* Support */}
      <Card className={`${classes.root} ${classes.flexBetween}`}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            <span
              style={{
                fontSize: "18px",
                color: "#707299",
              }}
            >
              {supportConfig.type[supportDetailsInfo.type]}
            </span>
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              marginTop: "16px",
            }}
          >
            <div>
              {supportDetailsInfo.imageUrl ? (
                <p
                  style={{
                    width: "480px",
                    height: "600px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  }}
                >
                  <img
                    alt={"Support Snapshot"}
                    src={supportDetailsInfo.imageUrl}
                    style={{ width: "100%", height: "100%" }}
                  />
                </p>
              ) : (
                <div
                  style={{
                    width: "480px",
                    height: "600px",
                    backgroundColor: "#ccc",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                  }}
                >
                  No Image
                </div>
              )}
            </div>
            <div style={{ marginLeft: "16px" }}>
              <h3>
                {supportDetailsInfo.customerRef
                  ? supportDetailsInfo.customerRef.personalInfo.name
                  : supportDetailsInfo.vendorRef.personalInfo.name}
              </h3>
              <p>
                {moment(supportDetailsInfo.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
              <p style={{ fontSize: "16px" }}>
                {" "}
                {supportDetailsInfo.description}
              </p>
            </div>
          </div>
        </CardContent>
        {supportDetailsInfo.status === 1 ? (
          <CardActions
            style={{
              alignItems: "baseline",
              marginTop: "10px",
            }}
          >
            <Fragment>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  approveSupport(supportDetailsInfo._id);
                }}
              >
                Approve
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.dangerBtn}
                onClick={() => {
                  rejectSupport(supportDetailsInfo._id);
                }}
              >
                Reject
              </Button>
            </Fragment>
          </CardActions>
        ) : (
          <Typography
            style={{
              width: "100px",
              paddingTop: "16px",
            }}
            color={supportDetailsInfo.status === 2 ? "secondary" : "error"}
            align="center"
          >
            {" "}
            {supportConfig.status[supportDetailsInfo.status]}
          </Typography>
        )}
      </Card>
    </React.Fragment>
  );
};

export default withStyles(
  (theme) => ({
    ...useStyles(theme),
  }),
  { withTheme: true }
)(SupportDetails);
