import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button, Modal, Image as BImage } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { addOrganization } from "../../../crud/organization.crud";
import * as organization from "../../../store/ducks/organization.duck";
const CreateOrganization = (props) => {
  const { classes } = props;
  const [errors, setErrors] = useState({
    name: null,
    profilePicture: null,
    email: null,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [preview, setPreview] = useState(null);
  const observed = useRef(null);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onChangeHandler = (event) => {
    let file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      console.log(img.width);
      console.log(img.height);
      if (img.width <= 400 && img.height <= 400) {
        console.log("true", true);
        setSelectedFile(file);
        setErrors({
          ...errors,
          icon: null,
        });
        return true;
      }
      setErrors({
        ...errors,
        icon: "Icon should not be grater than 400x400px",
      });
      return false;
    };
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
    } else {
      setErrors({
        ...errors,
        name: null,
      });
    }
  };

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
    } else {
      setErrors({
        ...errors,
        email: null,
      });
    }
  };

  const resetState = () => {
    setName("");
    setEmail("");
    setSelectedFile(undefined);
    setPreview(undefined);
    setErrors({ name: null, profilePicture: null, email: null });
  };

  const validForm = () => {
    let e = true;

    if (!name && !email) {
      setErrors({
        ...errors,
        name: "Name is required",
        email: "Profile Picture is required",
      });
      e = false;
    } else if (!name) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
      e = false;
    } else if (!email) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        name: null,
        email: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      addOrganization({ profilePicture: selectedFile, name, email })
        .then((output) => {
          if (output.data.success) {
            props.organizationAdded({ skip: 0, limit: 5 });
            resetState();
            setLoading(false);
            toast.success("Organization is successfully added!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("organizations");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          resetState();
          props.handleClose("organizations");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="organization.profilePicture">
              <Form.Label>Profile Picture</Form.Label>
              <div className="row">
                <div className="col-5">
                  <div className={classes.bigAvatar}>
                    {selectedFile ? (
                      <BImage src={preview} thumbnail />
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          width: "120px",
                          background: "#ccc",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          justifyContent: "center",
                          color: "#f6f6f6",
                          fontSize: "14px",
                        }}
                      >
                        <span>400 x 400 px</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-5">
                  <div className={classes.profileImageUploaderContainer}>
                    <div
                      className={classes.uploadImageButtonFile}
                      onClick={(e) => {
                        observed.current.click();
                      }}
                    >
                      <input
                        ref={observed}
                        type="file"
                        style={{
                          display: "none",
                        }}
                        onChange={onChangeHandler}
                      />
                      <img
                        src={toAbsoluteUrl("/media/icons/image-icon.png")}
                        alt="no-img"
                      />
                      <span>Browse Profile Picture</span>
                    </div>
                  </div>
                </div>
              </div>
              {errors.icon ? (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{errors.icon}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="organization.name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={onChangeNameHandler}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="organization.email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={onChangeEmailHandler}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              resetState();
              props.handleClose("organizations");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    organizationAdded: ({ skip, limit }) =>
      dispatch(organization.actions.organizationAdded({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(CreateOrganization)
  )
);
