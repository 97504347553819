import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import {
  listSupport,
  approveSupportDetails,
  rejectSupportDetails,
} from "../../../crud/support.crud";
import supportConfig from "../../../config/support";

const SupportList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.support.listData) {
      let listObject = {
        data: [...props.data.support.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.support.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.support.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.support.listData]);

  const reloadList = () => {
    listSupport({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (event, value) => {
    setPage(value);

    listSupport({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };
  const approveSupport = (supportId) => {
    approveSupportDetails(supportId).then(() => {
      reloadList();
    });
  };

  const rejectSupport = (supportId) => {
    rejectSupportDetails(supportId).then(() => {
      reloadList();
    });
  };

  return (
    <React.Fragment>
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Description</th>
                <th>Type</th>
                <th>Asked By</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <Link to={`/support-details/${eachData._id}`}>
                        {eachData._id}
                      </Link>
                    </td>
                    <td>{eachData.description}</td>
                    <td>{supportConfig.type[eachData.type]}</td>
                    <td>
                      {eachData.customerRef
                        ? eachData.customerRef.personalInfo.name
                        : eachData.vendorRef.personalInfo.name}
                    </td>
                    <td>
                      {moment(eachData.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>

                    <td>
                      {eachData.status === 1 ? (
                        <div>
                          <p
                            className="btn btn-sm btn-clean btn-icon btn-icon-md"
                            title="Approve"
                            onClick={() => {
                              approveSupport(eachData._id);
                            }}
                            style={{
                              margin: "0",
                            }}
                          >
                            <i className="la la-check-circle"></i>
                          </p>

                          <p
                            className="btn btn-sm btn-clean btn-icon btn-icon-md deleteProfessionalFaqBtn"
                            title="Reject"
                            style={{
                              margin: "0",
                            }}
                            onClick={() => {
                              rejectSupport(eachData._id);
                            }}
                          >
                            <i className="la la-close kt-font-danger"></i>
                          </p>
                        </div>
                      ) : (
                        <Typography
                          color={eachData.status === 2 ? "secondary" : "error"}
                          align="center"
                        >
                          {supportConfig.status[eachData.status]}
                        </Typography>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p>No Data Available</p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportList);
