import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import EditProblem from "./edit-problem";
import RemoveProblem from "./remove-problem";
import { listProblem } from "../../../crud/problem.crud";

const ProblemList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [showProblemEdit, setShowProblemEdit] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [showProblemDelete, setShowProblemDelete] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.problem.listData) {
      // console.log('props.data.problem.listData',
      // props.data.problem.listData);
      let listObject = {
        data: [...props.data.problem.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.problem.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.problem.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.problem.listData]);

  const reloadList = () => {
    listProblem({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        // console.log(docs);
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (event, value) => {
    console.log("value", value);
    setPage(value);

    listProblem({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowProblemEdit(false);
  };

  const handleCloseDelete = () => {
    setShowProblemDelete(false);
  };

  const openEditModal = (problem) => {
    setSelectedData(problem);
    setShowProblemEdit(true);
  };

  const openDeleteModal = (problem) => {
    setSelectedData(problem);
    setShowProblemDelete(true);
  };

  return (
    <React.Fragment>
      {showProblemEdit ? (
        <EditProblem
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          problem={selectedData}
          show={showProblemEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showProblemDelete ? (
        <RemoveProblem
          problem={selectedData}
          show={showProblemDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Problem Category</th>
                <th>Language Sets</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key}>
                    <td>{eachData._id}</td>

                    <td>{eachData.problemCategoryRef.categoryTitle}</td>
                    <td>
                      <ul>
                        {eachData.languageSet.map((v, key) => {
                          return (
                            <li key={key}>
                              <p>
                                <span>{v.title}</span>{" "}
                                <span style={{ fontSize: "10px" }}>
                                  {v.languageRef.name}
                                </span>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </td>
                    <td>
                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md deleteProfessionalProblemBtn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p>No Data Available</p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProblemList);
