import React, { useEffect, useState } from "react";
import { Table, Image } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import EditUser from "./edit-user";
import RemoveUser from "./remove-user";
import moment from "moment";
import { listUser } from "../../../crud/user.crud";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

const UserList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [showUserEdit, setShowUserEdit] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [showUserDelete, setShowUserDelete] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.user.listData) {
      let listObject = {
        data: [...props.data.user.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.user.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.user.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.user.listData]);

  const reloadList = () => {
    listUser({ skip: tableData.skip, limit: tableData.limit }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleChange = (_event, value) => {
    // console.log("value", value);
    setPage(value);

    listUser({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowUserEdit(false);
  };

  const handleCloseDelete = () => {
    setShowUserDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowUserEdit(true);
  };

  const openDeleteModal = (data) => {
    setSelectedData(data);
    setShowUserDelete(true);
  };

  const renderGender = (selectedGender) => {
    let genderText = "N/A";
    switch (selectedGender) {
      case 1:
        genderText = "Male";
        break;
      case 2:
        genderText = "Female";
        break;
      case 3:
        genderText = "Other";
        break;
      default:
        break;
    }
    return genderText;
  };

  return (
    <React.Fragment>
      {showUserEdit ? (
        <EditUser
          user={selectedData}
          show={showUserEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showUserDelete ? (
        <RemoveUser
          user={selectedData}
          show={showUserDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Profile</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id}>
                    <td>{eachData._id}</td>
                    <td style={{ textAlign: "center" }}>
                      {eachData.personalInfo.profilePicture ? (
                        <Image
                          src={eachData.personalInfo.profilePicture}
                          style={{
                            height: "120px",
                          }}
                        />
                      ) : (
                        <Image
                          alt={`user logo`}
                          src={toAbsoluteUrl(
                            `/media/default-image/default-image.png`
                          )}
                          style={{
                            height: "120px",
                          }}
                        />
                      )}
                    </td>
                    <td>
                      {eachData.personalInfo.name
                        ? eachData.personalInfo.name
                        : "N/A"}
                    </td>
                    <td>{`${eachData.personalInfo.phone.dialCode}-${eachData.personalInfo.phone.number}`}</td>
                    <td>
                      {eachData.personalInfo.email
                        ? eachData.personalInfo.email
                        : "N/A"}
                    </td>
                    <td>{renderGender(eachData.personalInfo.gender)}</td>
                    <td>
                      {eachData.personalInfo.dob
                        ? moment(eachData.personalInfo.dob).format(
                            "DD-MMM-YYYY"
                          )
                        : "N/A"}
                    </td>
                    <td>
                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md deleteProfessionalBtn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p>No Data Available</p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
