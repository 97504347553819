const ApiErrorMessage = {
  1000: "AUTH.VALIDATION.SESSION_NOT_FOUND",
  1001: "AUTH.VALIDATION.INVALID_OTP",
  1002: "AUTH.VALIDATION.OTP_TIMEOUT",
  1003: "AUTH.VALIDATION.PASSWORD_MISMATCH",

  1100: "AUTH.VALIDATION.INVALID_LOGIN",
  1101: "AUTH.VALIDATION.ADMIN_EMAIL_ALREADY_EXISTS",

  1300: "AUTH.VALIDATION.VENDOR_CATEGORY_ALREADY_EXISTS",
  1301: "AUTH.VALIDATION.VENDOR_CATEGORY_NOT_FOUND",
  1302: "AUTH.VALIDATION.VENDOR_CATEGORY_UNABLE_TO_REMOVE",
  1303: "AUTH.VALIDATION.VENDOR_CATEGORY_TAGGED_WITH_VENDOR",

  1400: "AUTH.VALIDATION.VENDOR_ALREADY_EXISTS",

  1500: "AUTH.VALIDATION.ORGANISER_EMAIL_ALREADY_EXISTS",
  1501: "AUTH.VALIDATION.ORGANISER_NOT_FOUND",

  1700: "AUTH.VALIDATION.LANGUAGE_ALREADY_EXISTS",
  1701: "AUTH.VALIDATION.LANGUAGE_NOT_FOUND",
  1702: "AUTH.VALIDATION.UNABLE_TO_REMOVE_LANGUAGE",
  1703: "AUTH.VALIDATION.UNABLE_TO_REMOVE_LANGUAGE_PROBLEM_CATEGORY_PROBLEM",

  1800: "AUTH.VALIDATION.PROBLEM_CATEGORY_ALREADY_EXISTS",
  1801: "AUTH.VALIDATION.PROBLEM_CATEGORY_NOT_FOUND",
  1802: "AUTH.VALIDATION.UNABLE_TO_REMOVE_PROBLEM_CATEGORY",
  1803: "AUTH.VALIDATION.PROBLEM_CATEGORY_TAGGED_WITH_PROBLEM",

  1900: "AUTH.VALIDATION.PROBLEM_ALREADY_EXISTS",
  1901: "AUTH.VALIDATION.PROBLEM_NOT_FOUND",
  1902: "AUTH.VALIDATION.UNABLE_TO_REMOVE_PROBLEM",

  2000: "AUTH.VALIDATION.FAQ_ALREADY_EXISTS",
  2001: "AUTH.VALIDATION.FAQ_NOT_FOUND",
  2002: "AUTH.VALIDATION.UNABLE_TO_REMOVE_FAQ",
};

export default ApiErrorMessage;
