import React, {useEffect, useState, Fragment} from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Form, Button as RBButton} from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from "@material-ui/core/styles";
import LayoutConfig from '../../../../_metronic/layout/LayoutConfig';
import {useParams} from "react-router-dom";
import moment from 'moment';
import {qaDetails,answerQa, approveQaDetails, rejectQaDetails, approveQaAnswerDetails, rejectQaAnswerDetails} from './../../../crud/qa.crud';
import qaConfig from './../../../config/qa';
import { green } from '@material-ui/core/colors';

const useStyles = (theme) => ({
    root: {
        minWidth: 275
    },
    title: {
        fontSize: 14,
        marginBottom: 0
    },
    pos: {
        marginBottom: theme.spacing(1)
    },
    flexBetween: {
        display: 'flex !important',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    dangerBtn: {
        backgroundColor: LayoutConfig.colors.state.danger,
        color: 'white',
        "&:hover": {
            backgroundColor: LayoutConfig.colors.state.danger,
            color: 'white'
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
});

const QADetails = (props) => {
    const {qaId} = useParams();
    const classes = props.classes;
    const [qaDetailsInfo, setQaDetailsInfo] = useState();
    const [answer, setAnswer] = useState('');
    const [errors, setErrors] = useState({answer: null});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        qaDetails(qaId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onChangeHandlerForAnswer = (event) => {
        setAnswer(event.target.value);
    }

    const handleSubmit = ()=>{
        if(!answer){
            setErrors({
                answer:'Answer needs to be given'
            })
        }else{
            setLoading(true);
            answerQa(qaId, {answer})
            .then(()=>{
                qaDetails(qaId).then((output) => {
                    setQaDetailsInfo({
                        ...qaDetailsInfo,
                        ...output.data.data
                    });
                    setAnswer('');
                    setLoading(false);
                });

            });
        }
    };

    const approveQA = (qaId) => {
        approveQaDetails(qaId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
    };

    const rejectQA = (qaId) => {
        rejectQaDetails(qaId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });;
    }

    const approveQaAnswerHandler = (qaId, answerId) => {
        approveQaAnswerDetails(qaId, answerId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
    };

    const rejectQaAnswerHandler = (qaId, answerId) => {
        rejectQaAnswerDetails(qaId, answerId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });;
    }

    if (!qaDetailsInfo) {
        return null;
    }

    return (
        <React.Fragment>
            {/* Question */}
            <Card className={`${classes.root} ${classes.flexBetween}`}>
                <CardContent>
                    <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{
                        display: 'flex'
                    }}>
                        <span
                            style={{
                            fontSize: '18px',
                            color: '#707299'
                        }}>Question&nbsp;:&nbsp;</span>
                        <span className="mt-1">
                            {qaDetailsInfo.question}
                            <span className="mt-3 d-block">
                                <span
                                    style={{
                                    color: '#4d59b6'
                                }}>Asked By&nbsp;:&nbsp;</span>
                                <span>{qaDetailsInfo.askedBy.personalInfo.name}</span>
                                <span
                                    style={{
                                    color: '#4d59b6'
                                }}>
                                    | Date &nbsp;:&nbsp;</span>
                                <span>{moment(qaDetailsInfo.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                            </span>
                        </span>
                    </Typography>

                </CardContent>
                {qaDetailsInfo.status === 1
                    ? <CardActions
                            style={{
                            alignItems: 'baseline',
                            marginTop: '10px'
                        }}>
                            <Fragment>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                    approveQA(qaDetailsInfo._id);
                                }}>Approve</Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={classes.dangerBtn}
                                    onClick={() => {
                                    rejectQA(qaDetailsInfo._id);
                                }}>Reject</Button>
                            </Fragment>
                        </CardActions>
                    : <Typography
                        style={{
                        width: '100px',
                        paddingTop: '16px'
                    }}
                        color={qaDetailsInfo.status === 2
                        ? "secondary"
                        : "error"}
                        align="center">{qaConfig.answerStatus[qaDetailsInfo.status]}</Typography>}

            </Card>

            {/* Answer */}
            {qaDetailsInfo.answers && qaDetailsInfo.answers.length
                ? qaDetailsInfo
                    .answers.filter((each)=>each? true: false)
                    .map((eachAnswer, key) => {
                        return (
                            <Card className={`${classes.root} ${classes.flexBetween} mt-3`} key={key}>
                                <CardContent>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                        style={{
                                        display: 'flex'
                                    }}>
                                        <span
                                            style={{
                                            fontSize: '18px',
                                            color: '#707299'
                                        }}>Answer&nbsp;:&nbsp;</span>
                                        <span className="mt-1">
                                            {eachAnswer.answer}
                                            <span className="mt-3 d-block">
                                                <span
                                                    style={{
                                                    color: '#4d59b6'
                                                }}>Answered By&nbsp;:&nbsp;</span>
                                                <span>{eachAnswer.answeredBy.userType!==3 ? eachAnswer.answeredBy.personalInfo.name: eachAnswer.answeredBy.personalInfo.fullName}</span>
                                                <span
                                                    style={{
                                                    color: '#4d59b6'
                                                }}>
                                                    | Date &nbsp;:&nbsp;</span>
                                                <span>{moment(eachAnswer.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                            </span>
                                        </span>
                                    </Typography>

                                </CardContent>
                                {eachAnswer.status === 1
                                    ? <CardActions
                                            style={{
                                            alignItems: 'baseline',
                                            marginTop: '10px'
                                        }}>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                approveQaAnswerHandler(qaDetailsInfo._id, eachAnswer._id);
                                            }}>Approve</Button>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                className={classes.dangerBtn}
                                                onClick={() => {
                                                rejectQaAnswerHandler(qaDetailsInfo._id, eachAnswer._id);
                                            }}>Reject</Button>
                                        </CardActions>
                                    : <Typography
                                        style={{
                                        width: '100px',
                                        paddingTop: '16px'
                                    }}
                                        color={eachAnswer.status === 2
                                        ? "secondary"
                                        : "error"}
                                        align="center">{qaConfig.answerStatus[eachAnswer.status]}</Typography>}
                            </Card>
                        );
                    })
                : null
            }
            <Form.Group style={{marginTop: 16}}>
                        <Form.Label>Answer</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            cols={50}
                            value={answer}
                            onChange={onChangeHandlerForAnswer}
                            isInvalid={!!errors.answer}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.answer}
                        </Form.Control.Feedback>

                    </Form.Group>
                    <RBButton
                        variant="primary"
                        style={{
                        position: "relative"
                    }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Save Changes
                    </RBButton>

        </React.Fragment>
    )

};

export default withStyles((theme) => ({
    ...useStyles(theme)
}), {withTheme: true})(QADetails);