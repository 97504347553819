import axios from './axios.interceptor';
import api from './api.util';

export const PAYMENT_URL = `${api}/account/admin/payment`;
export const PAYMENT_LIST_URL =`${PAYMENT_URL}/list`;
export const PAYMENT_PAYOUT = `${PAYMENT_URL}/submit-payout`;

export function paymentDetails(payload) {
  return axios.post(`${PAYMENT_URL}/details`, payload);
}

export function listPayment({skip, limit, queryPayload}) {
  return axios.post(`${PAYMENT_LIST_URL}?skip=${skip}&limit=${limit}`, queryPayload);
}

export function submitPayout(payload) {
  return axios.post(`${PAYMENT_PAYOUT}`, payload);
}