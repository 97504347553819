import axios from './axios.interceptor';
import api from './api.util';

export const PROBLEM_URL = `${api}/account/admin/problem`;
export const ADD_PROBLEM_URL =`${PROBLEM_URL}/add`;
export const LIST_PROBLEM_URL =`${PROBLEM_URL}/list`;
export const UPLOAD_AUDIO_URL = `${api}/account/admin/common/upload-audio`;

export function uploadAudioFile(audioFile) {
  const formData = new FormData();
  if(audioFile){
    formData.append('audio',audioFile);
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.post(UPLOAD_AUDIO_URL, formData,config);
}

export function addProblem(payload) {
  return axios.post(ADD_PROBLEM_URL, payload);
}

export function listProblem({skip, limit}) {
  return axios.get(`${LIST_PROBLEM_URL}?skip=${skip}&limit=${limit}`);
}

export function editProblem(problemId, payload) {
  return axios.put(`${PROBLEM_URL}/${problemId}/update`, payload);
}

export function removeProblem(problemId) {
  return axios.delete(`${PROBLEM_URL}/${problemId}/remove`);
}