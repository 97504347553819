import axios from './axios.interceptor';
import api from './api.util';

export const ORGANIZATION_URL = `${api}/account/admin/organiser`;
export const ADD_ORGANIZATION_URL =`${ORGANIZATION_URL}/add`;
export const LIST_ORGANIZATION_URL =`${ORGANIZATION_URL}/list`;

export function addOrganization({profilePicture, name, email}) {
  const formData = new FormData();
  formData.append('profilePicture',profilePicture);
  formData.append('name',name);
  formData.append('email',email);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.post(ADD_ORGANIZATION_URL, formData,config);
}

export function listOrganization({skip, limit}) {
  return axios.get(`${LIST_ORGANIZATION_URL}?skip=${skip}&limit=${limit}`);
}

export function editOrganization(organizationId, payload) {
  const formData = new FormData();
  if(payload.profilePicture){
    formData.append('profilePicture',payload.profilePicture);
  }

  if(payload.name){
    formData.append('name',payload.name);
  }

  if(payload.email){
    formData.append('email',payload.email);
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  
  return axios.put(`${ORGANIZATION_URL}/${organizationId}/update`, formData,config);
}

export function removeOrganization(organizationId) {
  return axios.delete(`${ORGANIZATION_URL}/${organizationId}/remove`);
}