import axios from './axios.interceptor';
import api from './api.util';

export const VENDOR_URL = `${api}/account/admin/vendor`;
export const ADD_VENDOR_URL =`${VENDOR_URL}/add`;
export const LIST_VENDOR_URL =`${VENDOR_URL}/list`;

export function addVendor(payload) {
  return axios.post(ADD_VENDOR_URL, payload);
}

export function listVendor({skip, limit}) {
  return axios.post(`${LIST_VENDOR_URL}?skip=${skip}&limit=${limit}`);
}

export function editVendor(vendorId, payload) {
  return axios.put(`${VENDOR_URL}/${vendorId}/update`, payload);
}

export function removeVendor(vendorId) {
  return axios.delete(`${VENDOR_URL}/${vendorId}/remove`);
}