import {listQA} from "../../crud/qa.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    QA_ADDED: "QA_ADDED",
    QA_UPDATED: "QA_UPDATED",
    QA_UPDATED_SUCCESS: "QA_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
       
        case actionTypes.QA_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
  
    qaUpdated: ({skip, limit}) => ({
        type: actionTypes.QA_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {

    yield takeLatest(actionTypes.QA_UPDATED, function * qaUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listQA({skip, limit});

        yield put({type: actionTypes.QA_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
