import axios from './axios.interceptor';
import api from './api.util';

export const BOOKING_URL = `${api}/account/admin/booking`;
export const BOOKING_LIST_URL =`${BOOKING_URL}/list`;

export function bookingDetails(bookingId) {
  return axios.get(`${BOOKING_URL}/${bookingId}`);
}

export function listBooking({skip, limit, bookingPayload}) {
  return axios.post(`${BOOKING_LIST_URL}?skip=${skip}&limit=${limit}`, bookingPayload);
}