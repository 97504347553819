import axios from "./axios.interceptor";
import api from "./api.util";

export const USER_URL = `${api}/account/admin/user`;
export const ADD_USER_URL = `${USER_URL}/add`;
export const LIST_USER_URL = `${USER_URL}/list`;

export function addUser(payload) {
  return axios.post(ADD_USER_URL, payload);
}

export function listUser({ skip, limit }) {
  return axios.get(`${LIST_USER_URL}?skip=${skip}&limit=${limit}`);
}

export function editUser(userId, payload) {
  const formData = new FormData();
  if (payload.profilePicture) {
    formData.append("profilePicture", payload.profilePicture);
  }

  if (payload.name) {
    formData.append("name", payload.name);
  }

  if (payload.email) {
    formData.append("email", payload.email);
  }

  if (payload.phone) {
    formData.append("phone", payload.phone.number);
  }

  if (payload.gender) {
    formData.append("gender", payload.gender);
  }

  if (payload.dob) {
    formData.append("dob", payload.dob);
  }

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.put(`${USER_URL}/${userId}/update`, formData, config);
}

export function removeUser(userId) {
  return axios.delete(`${USER_URL}/${userId}/remove`);
}
