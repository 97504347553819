import React, { useEffect, useState } from "react";
import { Table, Dropdown } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import bookingConfig from "././../../../config/booking";
import { listBooking } from "../../../crud/booking.crud";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const BookingList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Select Status"
  );
  const [selectedStatus, setSelectedStatus] = useState(undefined);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleMonthChange = (date) => {
    setSelectedDate(date);
    const startDate = moment(date)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(date)
      .endOf("month")
      .format("YYYY-MM-DD");
    reloadList({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      status: selectedStatus,
    });
  };

  const statusHandler = (status) => {
    setSelectedStatus(status);
    switch (status) {
      case 1:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 2:
        setSelectedBookingStatus("Confirmed");
        break;
      case 3:
        setSelectedBookingStatus("Completed");
        break;
      case 4:
        setSelectedBookingStatus("Cancelled by customer");
        break;
      case 5:
        setSelectedBookingStatus("Cancelled by vendor");
        break;
      case 6:
        setSelectedBookingStatus("Failed");
        break;
      case 7:
        setSelectedBookingStatus("Timed out");
        break;
      default:
        break;
    }
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    reloadList({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      status: status,
    });
  };

  useEffect(() => {
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    reloadList({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      status: selectedStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.user.listData) {
      let listObject = {
        data: [...props.data.user.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.user.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.user.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.user.listData]);

  const reloadList = (bookingPayload) => {
    listBooking({
      skip: tableData.skip,
      limit: tableData.limit,
      bookingPayload: {
        searchKey: undefined,
        startDate: bookingPayload.startDate,
        endDate: bookingPayload.endDate,
        status: bookingPayload.status,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleChange = (_event, value) => {
    setPage(value);
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");
    listBooking({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
      bookingPayload: {
        searchKey: undefined,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        status: selectedStatus,
      },
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const renderSlots = (slots) => {
    return (
      <p>
        {slots.map((eachSlot, key) => {
          if (slots.length - 1 === key) {
            return bookingConfig.slots[eachSlot];
          } else {
            return bookingConfig.slots[eachSlot] + ", ";
          }
        })}
      </p>
    );
  };

  const renderStatus = (status) => {
    let statusText = "N/A";
    switch (status) {
      case 2:
        statusText = "Confirmed";
        break;
      case 3:
        statusText = "Completed";
        break;
      case 4:
        statusText = "Cancelled By Customer";
        break;
      default:
        break;
    }
    return <p>{statusText}</p>;
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-between">
          <Grid>
            <Dropdown>
              <Dropdown.Toggle
                menuAlign="right"
                variant="primary"
                id="dropdown-menu-align-right"
              >
                {selectedBookingStatus}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(1);
                  }}
                >
                  Payment Pending
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(2);
                  }}
                >
                  Confirmed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(3);
                  }}
                >
                  Completed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(4);
                  }}
                >
                  Cancelled By Customer
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(5);
                  }}
                >
                  Cancelled By Vendor
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    statusHandler(6);
                  }}
                >
                  Failed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(7);
                  }}
                >
                  Timed out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Grid>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Year and Month"
            helperText="Start from year selection"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Professional Name</th>
                <th>Professional Phone</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Service</th>
                <th>Booked Slot</th>
                <th>Booked Date</th>
                <th>Booked Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id}>
                    <td>
                      ***{eachData._id.substr(eachData._id.length - 4, 4)}
                    </td>
                    <td>
                      {eachData.vendor.personalInfo.name
                        ? eachData.vendor.personalInfo.name
                        : "N/A"}
                    </td>
                    <td>{`${eachData.vendor.personalInfo.phone.dialCode}-${eachData.vendor.personalInfo.phone.number}`}</td>
                    <td>
                      {eachData.customer.personalInfo.name
                        ? eachData.customer.personalInfo.name
                        : "N/A"}
                    </td>
                    <td>{`${eachData.customer.personalInfo.phone.dialCode}-${eachData.customer.personalInfo.phone.number}`}</td>
                    <td>
                      {eachData.service.title ? eachData.service.title : "N/A"}
                    </td>
                    <td>{renderSlots(eachData.slots)}</td>
                    <td>
                      {eachData.date
                        ? moment(eachData.date).format("DD-MMM-YYYY")
                        : "N/A"}
                    </td>
                    <td>{renderStatus(eachData.status)}</td>
                    <td>
                      <Link to={`/booking-details/${eachData._id}`}>
                        <p
                          className="btn btn-sm btn-clean btn-icon btn-icon-md"
                          style={{
                            margin: "0",
                          }}
                        >
                          <i className="la la-eye"></i>
                        </p>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p>No Data Available</p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
