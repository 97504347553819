import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {connect} from "react-redux";
import {Form, Button, Modal} from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import {injectIntl} from "react-intl";
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {editFaq} from '../../../crud/faq.crud';
import * as faq from "../../../store/ducks/faq.duck";
const EditFaq = (props) => {
    const [loading,
        setLoading] = useState(false);
        const [errors,
            setErrors] = useState({title: null, icon: null});
    const [title,
        setTitle] = useState('');
    const [description,
        setDescription] = useState('');
    

    const {classes, faq} = props;

    useEffect(() => {
        const dataProps = JSON.parse(JSON.stringify(faq));
        setTitle(dataProps.title);
        setDescription(dataProps.description);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    const onTitleChangeHandler = (event) => {
        setTitle(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
    };




    const onDescriptionChangeHandler = (event) => {
        setDescription(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
    }

    const resetState = () => {
        setTitle('');
        setDescription('');
       
        setErrors({
            title: null, 
            description: null
        });
    }


    const validForm = () => {
        let e = true;

        if (!title && !description) {
            setErrors({
                ...errors,
                title: 'Title is required',
                description: 'Description is required'
            });
            e = false;
        } else if (!title) {
            setErrors({
                ...errors,
                title: 'Title is required',
                description: 'Description is required'
            });
            e = false;
        } else if (!description) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
            e = false;
        } else {
            setErrors({
                ...errors,
                title: null,
                description: null
            });
        }
        return e;
    }


    const handleSubmit = () => {
        if(validForm()){
            setLoading(true);
            
            const payload = {
                title: title,
                description: description
            };
    
            editFaq(faq._id, payload).then((output) => {
                if (output.data.success) {
                    props.faqUpdated({skip: props.listOption.skip, limit: props.listOption.limit});
                    resetState();
                    setLoading(false);
                    toast.success('Faq is successfully updated!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    props.handleClose('faq');
                }
            }).catch((error) => {
                console.log('error', error);
                setLoading(false);
                
            });
        }
        
    };

    return (
        <React.Fragment>
            <Modal show={props.show} onHide={() => props.handleClose('faq')}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Professional Faq</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>                        
                        <Form.Group controlId="professionalFaqForm.title">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={title} onChange={onTitleChangeHandler} isInvalid={!!errors.title}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="professionalFaqForm.description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" value={description} onChange={onDescriptionChangeHandler} isInvalid={!!errors.description}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose('faq')}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                        position: "relative"
                    }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {data: state};
};

const mapDispatchToProps = dispatch => {
    return {
        faqUpdated: ({skip, limit}) => dispatch(faq.actions.faqUpdated({skip, limit}))
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), {withTheme: true})(EditFaq)));
