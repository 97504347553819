/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";
import CreateRole from "./../../../app/pages/home/role-management/create-role";
import CreateVendorCategory from "./../../../app/pages/home/vendor-category-management/create-vendor-category";
import CreateVendorPage from "./../../../app/pages/home/vendor-management/create-vendor-page";
import CreateProblemCategory from "./../../../app/pages/home/problem-category-management/create-problem-category";
import CreateProblem from "./../../../app/pages/home/problem/create-problem";
import CreateLanguage from "./../../../app/pages/home/language-management/create-language";
import CreateFaq from "./../../../app/pages/home/faq/create-faq";
import CreateOrganization from "../../../app/pages/home/organization-management/create-organization";
import CreateSubAdmin from "./../../../app/pages/home/sub-admin-management/create-sub-admin";
const SubHeader = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
  } = props;
  const [showAddRole, setShowAddRole] = useState(false);
  const [showVendorAddCategory, setShowVendorAddCategory] = useState(false);
  const [showVendorAdd, setShowVendorAdd] = useState(false);
  const [showProblemAddCategory, setShowProblemAddCategory] = useState(false);
  const [showProblemAdd, setShowProblemAdd] = useState(false);
  const [showAddLanguage, setShowAddLanguage] = useState(false);
  const [showAddFaq, setShowAddFaq] = useState(false);
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [showAddSubAdmin, setShowAddSubAdmin] = useState(false);
  const [currentPageName, setCurrentPageName] = useState("");
  const handleClose = (page) => {
    switch (page) {
      case "role":
        setShowAddRole(false);
        break;
      case "subAdmin":
        setShowAddSubAdmin(false);
        break;
      case "organizations":
        setShowAddOrganization(false);
        break;
      case "professional-category":
        setShowVendorAddCategory(false);
        break;
      case "professionals":
        setShowVendorAdd(false);
        break;
      case "languages":
        setShowAddLanguage(false);
        break;
      case "problemCategory":
        setShowProblemAddCategory(false);
        break;
      case "problems":
        setShowProblemAdd(false);
        break;
      case "faq":
        setShowAddFaq(false);
        break;
      default:
        break;
    }
  };

  const handleShow = (page) => {
    switch (page) {
      case "role":
        setShowAddRole(true);
        break;
      case "subAdmin":
        setShowAddSubAdmin(true);
        break;
      case "organizations":
        setShowAddOrganization(true);
        break;
      case "professional-category":
        setShowVendorAddCategory(true);
        break;
      case "professionals":
        setShowVendorAdd(true);
        break;
      case "languages":
        setShowAddLanguage(true);
        break;
      case "problemCategory":
        setShowProblemAddCategory(true);
        break;
      case "problems":
        setShowProblemAdd(true);
        break;
      case "faq":
        setShowAddFaq(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf("booking-details") !== -1) {
      setCurrentPageName("Booking Details");
    } else if (location.pathname.indexOf("forum-details") !== -1) {
      setCurrentPageName("Forum Details");
    } else if (location.pathname.indexOf("payment-details") !== -1) {
      setCurrentPageName("Payment Details");
    } else if (location.pathname.indexOf("support-details") !== -1) {
      setCurrentPageName("Support Details");
    }
    return () => {};
  }, [location.pathname, setCurrentPageName]);

  return (
    <div
      id="kt_subheader"
      className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
    >
      {showAddRole ? (
        <CreateRole show={showAddRole} handleClose={handleClose} />
      ) : null}
      {showVendorAddCategory ? (
        <CreateVendorCategory
          show={showVendorAddCategory}
          handleClose={handleClose}
        />
      ) : null}
      {showVendorAdd ? (
        <CreateVendorPage show={showVendorAdd} handleClose={handleClose} />
      ) : null}
      {setShowAddOrganization ? (
        <CreateOrganization
          show={showAddOrganization}
          handleClose={handleClose}
        />
      ) : null}
      {showAddSubAdmin ? (
        <CreateSubAdmin show={showAddSubAdmin} handleClose={handleClose} />
      ) : null}
      {showProblemAddCategory ? (
        <CreateProblemCategory
          show={showProblemAddCategory}
          handleClose={handleClose}
        />
      ) : null}
      {showProblemAdd ? (
        <CreateProblem show={showProblemAdd} handleClose={handleClose} />
      ) : null}
      {showAddLanguage ? (
        <CreateLanguage show={showAddLanguage} handleClose={handleClose} />
      ) : null}
      {showAddFaq ? (
        <CreateFaq show={showAddFaq} handleClose={handleClose} />
      ) : null}
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="kt-subheader__main">
          {subheaderMobileToggle && (
            <button
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          <LayoutContextConsumer>
            {({ subheader: { title } }) => (
              <>
                <h3 className="kt-subheader__title">
                  {title
                    ? title
                    : location.state
                    ? location.state.title
                    : currentPageName}
                </h3>
              </>
            )}
          </LayoutContextConsumer>
        </div>

        <div className="kt-subheader__toolbar">
          <div className="kt-subheader__wrapper">
            <LayoutContextConsumer>
              {({ subheader: { title } }) => (
                <Fragment>
                  {(title &&
                    (title === "Dashboard" ||
                      title === "Bookings" ||
                      title === "Payments" ||
                      title === "Global Setting" ||
                      title === "Forums" ||
                      title === "Supports")) ||
                  title === "User" ? null : currentPageName ===
                      "Forum Details" ||
                    currentPageName === "Payment Details" ||
                    currentPageName === "Support Details" ? (
                    <a
                      className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      onClick={history.goBack}
                    >
                      Back
                    </a>
                  ) : title ? (
                    <a
                      onClick={() => {
                        switch (title) {
                          case "Role":
                            handleShow("role");
                            break;
                          case "Sub Admin":
                            handleShow("subAdmin");
                            break;
                          case "Organizations":
                            handleShow("organizations");
                            break;
                          case "Professional Category":
                            handleShow("professional-category");
                            break;
                          case "Professionals":
                            handleShow("professionals");
                            break;
                          case "Languages":
                            handleShow("languages");
                            break;
                          case "Problem Category":
                            handleShow("problemCategory");
                            break;
                          case "Problems":
                            handleShow("problems");
                            break;
                          case "FAQ":
                            handleShow("faq");
                            break;
                          default:
                            break;
                        }
                      }}
                      className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
                    >
                      Add New
                    </a>
                  ) : null}
                </Fragment>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true,
  }),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
