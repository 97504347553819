import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { listPayment } from "../../../crud/payment.crud";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const PaymentList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleMonthChange = (date) => {
    console.log("month", date);
    reloadList({
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1,
    });
    // setSelectedDate(date);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    reloadList({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.user.listData) {
      let listObject = {
        data: [...props.data.user.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.user.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.user.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.user.listData]);

  const reloadList = ({ year, month }) => {
    listPayment({
      skip: tableData.skip,
      limit: tableData.limit,
      queryPayload: {
        month: month,
        year: year,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleChange = (_event, value) => {
    // console.log("value", value);
    setPage(value);

    listPayment({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
      queryPayload: {
        year: new Date(selectedDate).getFullYear(),
        month: new Date(selectedDate).getMonth() + 1,
      },
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-end">
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Year and Month"
            helperText="Start from year selection"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Professional Name</th>
                <th>Professional Phone</th>
                <th>Total Service Requests</th>
                <th>Total Service Fees</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={`${eachData._id}`}>
                    <td>{`${eachData._id}`}</td>
                    <td>
                      {eachData.vendorRef.personalInfo.name
                        ? eachData.vendorRef.personalInfo.name
                        : "N/A"}
                    </td>
                    <td>{`${eachData.vendorRef.personalInfo.phone.dialCode}-${eachData.vendorRef.personalInfo.phone.number}`}</td>
                    <td>{eachData.totalServiceRequests}</td>
                    <td>{eachData.totalServiceFees}</td>
                    <td>
                      <Link to={`/payment-details/${eachData.vendorRef._id}`}>
                        <p
                          className="btn btn-sm btn-clean btn-icon btn-icon-md"
                          style={{
                            margin: "0",
                          }}
                        >
                          <i className="la la-eye"></i>
                        </p>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p>No Data Available</p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
