import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as account from "./ducks/account.duck";
import * as role from "./ducks/role.duck";
import * as subAdmin from "./ducks/sub-admin.duck";
import * as vendorCategory from "./ducks/vendor-category.duck";
import * as problemCategory from "./ducks/problem-category.duck";
import * as problem from "./ducks/problem.duck";
import * as language from "./ducks/language.duck";
import * as faq from "./ducks/faq.duck";
import * as qa from "./ducks/qa.duck";
import * as organization from "./ducks/organization.duck";
import * as vendor from "./ducks/vendor.duck";
import * as user from "./ducks/user.duck";

import * as support from "./ducks/support.duck";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  account: account.reducer,
  role: role.reducer,
  subAdmin: subAdmin.reducer,
  vendorCategory: vendorCategory.reducer,
  problemCategory: problemCategory.reducer,
  problem: problem.reducer,
  language: language.reducer,
  faq: faq.reducer,
  qa: qa.reducer,
  organization: organization.reducer,
  vendor: vendor.reducer,
  user: user.reducer,
  support: support.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    account.saga(),
    role.saga(),
    subAdmin.saga(),
    vendorCategory.saga(),
    problemCategory.saga(),
    problem.saga(),
    language.saga(),
    faq.saga(),
    qa.saga(),
    support.saga(),
    organization.saga(),
    vendor.saga(),
    user.saga(),
  ]);
}
