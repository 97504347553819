import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import ViewProfile from "./account/view-profile";
import RoleList from "./role-management/role-list";
import SubAdminList from "./sub-admin-management/sub-admin-list";
import OrganizationList from "./organization-management/organization-list";
import VendorCategoryList from "./vendor-category-management/vendor-category-list";
import VendorList from "./vendor-management/vendor-list";
import ProblemCategoryList from "./problem-category-management/problem-category-list";
import ProblemList from "./problem/problem-list";
import LanguageList from "./language-management/language-list";
import UserList from "./user-management/user-list";
import QAList from "./qa-management/qa-list";
import QADetails from "./qa-management/qa-details";
import BookingList from "./booking-management/booking-list";
import BookingDetails from "./booking-management/booking-details";
import PaymentList from "./payment-management/payment-list";
import PaymentDetails from "./payment-management/payment-details";
import SupportList from "./support-management/support-list";
import SupportDetails from "./support-management/support-details";
import FaqList from "./faq/faq-list";
import GlobalSettings from "./global-settings/index";
import { LayoutSplashScreen } from "../../../_metronic";
import * as account from "../../store/ducks/account.duck";
const HomePage = (props) => {
  useEffect(() => {
    props.accountUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/builder" component={Builder} />
        <Route path="/profile" component={ViewProfile} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/roles" component={RoleList} />
        <Route path="/sub-admins" component={SubAdminList} />
        <Route path="/organizations" component={OrganizationList} />
        <Route path="/professional-categories" component={VendorCategoryList} />
        <Route path="/professionals" component={VendorList} />
        <Route path="/languages" component={LanguageList} />
        <Route path="/problem-categories" component={ProblemCategoryList} />
        <Route path="/problems" component={ProblemList} />
        <Route path="/forums" component={QAList} />
        <Route path="/forum-details/:qaId" component={QADetails} />
        <Route path="/faqs" component={FaqList} />
        <Route path="/users" component={UserList} />
        <Route path="/bookings" component={BookingList} />
        <Route path="/booking-details/:bookingId" component={BookingDetails} />
        <Route path="/payments" component={PaymentList} />
        <Route path="/payment-details/:id" component={PaymentDetails} />
        <Route path="/supports" component={SupportList} />
        <Route path="/support-details/:supportId" component={SupportDetails} />
        <Route path="/global-setting" component={GlobalSettings} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
};
const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountUpdated: () => dispatch(account.actions.accountUpdated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
