import React, { useEffect, useState } from "react";
import { Table, Image } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import EditSubAdmin from "./edit-sub-admin";
import RemoveSubAdmin from "./remove-sub-admin";
import { listSubAdmin } from "../../../crud/sub-admin.crud";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { AspectRatio } from "react-aspect-ratio";
const SubAdminList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [showSubAdminEdit, setShowSubAdminEdit] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [showSubAdminDelete, setShowSubAdminDelete] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.subAdmin.listData) {
      let listObject = {
        data: [...props.data.subAdmin.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.subAdmin.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.subAdmin.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.subAdmin.listData]);

  const reloadList = () => {
    listSubAdmin({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (_event, value) => {
    // console.log("value", value);
    setPage(value);

    listSubAdmin({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowSubAdminEdit(false);
  };

  const handleCloseDelete = () => {
    setShowSubAdminDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowSubAdminEdit(true);
  };

  const openDeleteModal = (data) => {
    setSelectedData(data);
    setShowSubAdminDelete(true);
  };

  return (
    <React.Fragment>
      {showSubAdminEdit ? (
        <EditSubAdmin
          subAdmin={selectedData}
          show={showSubAdminEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showSubAdminDelete ? (
        <RemoveSubAdmin
          subAdmin={selectedData}
          show={showSubAdminDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Profile Picture</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id}>
                    <td>{eachData._id}</td>
                    <td style={{ textAlign: "center" }}>
                      {eachData.personalInfo.profilePicture ? (
                        <Image
                          src={eachData.personalInfo.profilePicture}
                          roundedCircle
                          style={{
                            height: "120px",
                          }}
                        />
                      ) : (
                        <AspectRatio
                          ratio="1/1"
                          style={{ maxWidth: "64px", maxHeight: "64px" }}
                        >
                          <img
                            alt={`user logo`}
                            src={toAbsoluteUrl(
                              `/media/default-image/default-image.png`
                            )}
                            style={{ maxWidth: "64px", maxHeight: "64px" }}
                          />
                        </AspectRatio>
                      )}
                    </td>
                    <td>
                      {eachData.personalInfo.firstName}{" "}
                      {eachData.personalInfo.lastName}
                    </td>
                    <td>{eachData.personalInfo.email}</td>
                    <td>
                      {eachData.roleInfo.isSuperAdmin
                        ? "Super Admin"
                        : eachData.roleInfo.roleId.name}
                    </td>
                    <td>
                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md deleteProfessionalBtn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p>No Data Available</p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminList);
