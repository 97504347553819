import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { Form, Button, Modal, Table, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { paymentDetails, submitPayout } from "./../../../crud/payment.crud";
import { getProfile } from "./../../../crud/vendor-profile.crud";
import moment from "moment";
import bookingConfig from "././../../../config/booking";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    marginTop: "2px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
}));

const BookingDetails = () => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { id } = useParams();
  const [vendorDetailsInfo, setVendorDetailsInfo] = useState(null);
  const [paymentDetailsInfo, setPaymentDetailsInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAmount, setSelectedAmount] = useState("");
  const [showSAModal, setShowSAModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const onAmountChangeHandler = (event) => {
    setSelectedAmount(event.target.value);
  };

  const handleMonthChange = (date) => {
    paymentDetails({
      vendorRef: id,
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1,
    }).then((output) => {
      if (output.data.data) {
        setPaymentDetailsInfo({
          ...paymentDetailsInfo,
          ...output.data.data,
        });
      } else {
        setPaymentDetailsInfo(null);
      }
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const renderSlots = (slots) => {
    return (
      <p>
        {slots.map((eachSlot, key) => {
          if (slots.length - 1 === key) {
            return bookingConfig.slots[eachSlot];
          } else {
            return bookingConfig.slots[eachSlot] + ", ";
          }
        })}
      </p>
    );
  };

  const renderStartTime = (slots) => {
    return renderSlots([slots[0]]);
  };

  const renderBookingStatus = (status) => {
    return bookingConfig.bookingStatus[status];
  };

  //   const renderTransferStatus = (status) => {
  //     return bookingConfig.transferStatus[status];
  //   };

  const submitPayoutFees = () => {
    if (selectedAmount) {
      setErrors(null);
      setLoading(true);
      submitPayout({
        vendorRef: id,
        month: new Date(selectedDate).getMonth() + 1,
        year: new Date(selectedDate).getFullYear(),
        date: new Date(),
        amount: Number(selectedAmount),
      }).then(() => {
        setLoading(false);
        setShowSAModal(false);
        toast.success("Payout successful!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    } else {
      setErrors({
        selectedAmount: "Amount is required!",
      });
    }
  };

  useEffect(() => {
    Promise.all([
      getProfile(id),
      paymentDetails({
        vendorRef: id,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      }),
    ]).then((output) => {
      const profileInfo = output[0];
      const paymentInfo = output[1];
      // console.log("profileInfo", profileInfo);
      // console.log("paymentInfo", paymentInfo);

      if (profileInfo.data.data) {
        setVendorDetailsInfo(profileInfo.data.data);
      }

      if (paymentInfo.data.data) {
        setPaymentDetailsInfo({
          ...paymentDetailsInfo,
          ...paymentInfo.data.data,
        });
      } else {
        setPaymentDetailsInfo(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight);
    }
  });

  if (!vendorDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        show={showSAModal}
        onHide={() => {
          setShowSAModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="amoountForm.title">
              <Form.Control
                type="text"
                value={selectedAmount}
                onChange={onAmountChangeHandler}
                isInvalid={errors && !!errors.selectedAmount}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.selectedAmount}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSAModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => submitPayoutFees()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-end">
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Year and Month"
            helperText="Start from year selection"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} ref={professionalRef}>
              <div className={classes.flexBox}>
                <div className="mr-3">
                  <img
                    src={
                      vendorDetailsInfo.personalInfo.profilePicture
                        ? vendorDetailsInfo.personalInfo.profilePicture
                        : toAbsoluteUrl(
                            `/media/default-image/default-image.png`
                          )
                    }
                    alt={`CustomersImage`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="text-left mt-1">
                  <h5>{vendorDetailsInfo.personalInfo.name}</h5>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Contact no -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.personalInfo.phone.dialCode}
                      {vendorDetailsInfo.personalInfo.phone.number}
                    </span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Email -&nbsp;</span>
                    <span>{vendorDetailsInfo.personalInfo.email}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    <span>Experience -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.personalInfo.experience.year} years
                    </span>{" "}
                    &nbsp;
                    {vendorDetailsInfo.personalInfo.experience.month ? (
                      <span>
                        {vendorDetailsInfo.personalInfo.experience.month} months
                      </span>
                    ) : null}
                  </span>
                  <div>
                    <span>Categories -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.personalInfo.categories.map(
                        (eachCat, key) => {
                          return (
                            <span key={key} className={classes.categoryTag}>
                              {eachCat.title}
                            </span>
                          );
                        }
                      )}
                    </span>
                  </div>
                  renderBookingStatus{" "}
                  <div>
                    <span>Website -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.personalInfo.website
                        ? vendorDetailsInfo.personalInfo.website
                        : "N/A"}
                    </span>
                  </div>
                  <div>
                    <span>Description -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.personalInfo.description
                        ? vendorDetailsInfo.personalInfo.description
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: height }}>
              <div className={classes.flexBox}>
                <div className="text-left mt-1">
                  <h5>Bank Details</h5>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Bank Name -&nbsp;</span>
                    <span>{vendorDetailsInfo.bankingDetails.bankName}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Account Holder Name -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.bankingDetails.accountHolderName}
                    </span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Account Number -&nbsp;</span>
                    <span>
                      {vendorDetailsInfo.bankingDetails.accountNumber}
                    </span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>IFSC Code -&nbsp;</span>
                    <span>{vendorDetailsInfo.bankingDetails.ifscCode}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Branch Name -&nbsp;</span>
                    <span>{vendorDetailsInfo.bankingDetails.branchName}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>GST Number -&nbsp;</span>
                    <span>{vendorDetailsInfo.bankingDetails.gstNumber}</span>
                  </span>
                </div>
              </div>
            </Paper>
          </Grid>
          {paymentDetailsInfo &&
          !paymentDetailsInfo.vendorPayout &&
          paymentDetailsInfo.paymentDetails.totalVendorDues ? (
            <Card
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "12px",
                margin: "8px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                Due Amount: {paymentDetailsInfo.paymentDetails.totalVendorDues}
              </div>
              <Button
                onClick={() => {
                  setShowSAModal(true);
                }}
              >
                Payout
              </Button>
            </Card>
          ) : paymentDetailsInfo && paymentDetailsInfo.vendorPayout ? (
            <Card
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "12px",
                margin: "8px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                Paid Amount: {paymentDetailsInfo.vendorPayout.amount} on{" "}
                {moment(paymentDetailsInfo.vendorPayout.date).format(
                  "DD-MMM-YYYY"
                )}
              </div>
            </Card>
          ) : null}
          {paymentDetailsInfo ? (
            <Grid item xs={12}>
              <Paper className={`${classes.paper} text-left`}>
                <h4 className="mb-3">Bookings</h4>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Start Time</th>
                      <th>Service Name</th>
                      <th>Booking Status</th>
                      <th>Service Fees</th>
                      {/* <th>Payable Amount</th>
                                        <th>Payable Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetailsInfo.paymentDetails.services.map(
                      (serviceRef, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {serviceRef.service.createdAt
                                ? moment(serviceRef.service.createdAt).format(
                                    "DD-MMM-YYYY"
                                  )
                                : "N/A"}
                            </td>
                            <td>{renderStartTime(serviceRef.slots)}</td>
                            <td>{serviceRef.service.title}</td>
                            <td>
                              {renderBookingStatus(serviceRef.service.status)}
                            </td>

                            <td>{serviceRef.paymentDetails.serviceFee}</td>
                            {/* <td>{serviceRef.transactionDetails.vendorDue}</td>
                                                <td>{renderTransferStatus(serviceRef.transactionDetails.transferStatus)}</td> */}
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td colSpan="4">Total</td>
                      <td>
                        {paymentDetailsInfo.paymentDetails.totalServiceFees}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Paper>
            </Grid>
          ) : (
            <p style={{ marginLeft: 16, marginTop: 16 }}>No Services found!</p>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default BookingDetails;
