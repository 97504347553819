import axios from './axios.interceptor';
import api from './api.util';

export const QA_URL = `${api}/account/admin/qa`;
export const QA_LIST_URL =`${QA_URL}/list`;

export function qaDetails(qaId) {
  return axios.get(`${QA_URL}/${qaId}`);
}

export function answerQa(qaId, payload) {
  return axios.post(`${QA_URL}/${qaId}/answer`, payload);
}

export function approveQaDetails(qaId) {
  return axios.put(`${QA_URL}/${qaId}/approve`);
}

export function rejectQaDetails(qaId) {
  return axios.put(`${QA_URL}/${qaId}/reject`);
}

export function approveQaAnswerDetails(qaId, answerId) {
  return axios.put(`${QA_URL}/${qaId}/answer/${answerId}/approve`);
}

export function rejectQaAnswerDetails(qaId, answerId) {
  return axios.put(`${QA_URL}/${qaId}/answer/${answerId}/reject`);
}

export function listQA({skip, limit}) {
  return axios.get(`${QA_LIST_URL}?skip=${skip}&limit=${limit}`);
}

export function editQA(qaId, payload) {
  return axios.put(`${QA_URL}/${qaId}/update`, payload);
}

export function removeQA(qaId) {
  return axios.delete(`${QA_URL}/${qaId}/remove`);
}