import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {toAbsoluteUrl} from "../../../../_metronic/utils/utils";
import {listGlobalSettings, editGlobalSettings} from '../../../crud/global-settings.crud';
import {Formik} from 'formik';
import "yup-phone";
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'row'
    }
});

const GlobalSettings = () => {

    const classes = useStyles();
    const [globalConfig,
        setGlobalConfig] = useState();

    const [loading,
        setLoading] = useState(false);

    useEffect(() => {
        listGlobalSettings({skip: 0, limit: 0}).then((data) => {
            setGlobalConfig(data.data.data);
        })
    }, []);

    const saveSetting = (payload) => {
        editGlobalSettings(payload).then((output) => {
            setGlobalConfig(output.data.data);
            setLoading(false);
        });
    };

    if (!globalConfig) {
        return null;
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
            appleStoreLink: {
                customer: globalConfig
                    ? globalConfig.appleStoreLink.customer
                    : null,
                vendor: globalConfig
                    ? globalConfig.appleStoreLink.vendor
                    : null
            },
            playStoreLink: {
                customer: globalConfig
                    ? globalConfig.playStoreLink.customer
                    : null,
                vendor: globalConfig
                    ? globalConfig.playStoreLink.vendor
                    : null
            },
            aboutUs: globalConfig
                ? globalConfig.aboutUs
                : null,
            termsAndCondition: globalConfig
                ? globalConfig.termsAndCondition
                : null,
            privacyPolicy: globalConfig
                ? globalConfig.privacyPolicy
                : null,
            cancellationPolicies:globalConfig
                ? globalConfig.cancellationPolicies
                : null, 
            supportContactNo: globalConfig
                ? globalConfig.supportContactNo
                : null,
            supportEmail: globalConfig
                ? globalConfig.supportEmail
                : null,
            convenienceFee: globalConfig
                ? globalConfig.convenienceFee
                : null,
            gstPercentage:globalConfig
                ? globalConfig.gstPercentage
                : null, 
            androidVersion:{
                customer: {
                    minimumSupportedVersion: globalConfig
                    ? globalConfig.androidVersion.customer.minimumSupportedVersion
                    : null,
                    currentVersion: globalConfig
                    ? globalConfig.androidVersion.customer.currentVersion
                    : null
                },
                professional:{
                    minimumSupportedVersion: globalConfig
                    ? globalConfig.androidVersion.professional.minimumSupportedVersion
                    : null,
                    currentVersion: globalConfig
                    ? globalConfig.androidVersion.professional.currentVersion
                    : null
                }
            },
            iOSVersion:{
                customer: {
                    minimumSupportedVersion: globalConfig
                    ? globalConfig.iOSVersion.customer.minimumSupportedVersion
                    : null,
                    currentVersion: globalConfig
                    ? globalConfig.iOSVersion.customer.currentVersion
                    : null
                },
                professional:{
                    minimumSupportedVersion: globalConfig
                    ? globalConfig.iOSVersion.professional.minimumSupportedVersion
                    : null,
                    currentVersion: globalConfig
                    ? globalConfig.iOSVersion.professional.currentVersion
                    : null,
                }
            }
        }}
            validationSchema={Yup.object({
                appleStoreLink: Yup.object({
                    customer: Yup
                        .string()
                        .url('Invalid url address')
                        .required(),
                    vendor: Yup
                        .string()
                        .url('Invalid url address')
                        .required()
                }),
                playStoreLink: Yup.object({
                    customer: Yup
                        .string()
                        .url('Invalid url address')
                        .required(),
                    vendor: Yup
                        .string()
                        .url('Invalid url address')
                        .required()
                }),
                aboutUs: Yup
                    .string()
                    .url('Invalid url address')
                    .required(),
                termsAndCondition: Yup
                    .string()
                    .url('Invalid url address')
                    .required(),
                privacyPolicy: Yup
                    .string()
                    .url('Invalid url address')
                    .required(),
                supportEmail: Yup
                    .string()
                    .email('Invalid email address')
                    .required(),
                supportContactNo: Yup
                    .string()
                    // eslint-disable-next-line
                    .phone('IN', true, '${path} is invalid') 
                    .required(),
                convenienceFee: Yup
                    .number()
                    .required(),
                gstPercentage: Yup
                    .number()
                    .required(), 
                androidVersion: Yup.object({
                    customer: Yup.object({
                        minimumSupportedVersion: Yup.number().required(),
                        currentVersion: Yup.number().required()
                    }),
                    professional:Yup.object({
                        minimumSupportedVersion: Yup.number().required(),
                        currentVersion: Yup.number().required()
                    })    
                }),
                iOSVersion: Yup.object({
                    customer: Yup.object({
                        minimumSupportedVersion: Yup.number().required(),
                        currentVersion: Yup.number().required()
                    }),
                    professional:Yup.object({
                        minimumSupportedVersion: Yup.number().required(),
                        currentVersion: Yup.number().required()
                    })
                })
            })}
            onSubmit={(values) => {
            setLoading(true);
            saveSetting(values);
        }}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
            }) => (

                <Card className={classes.root}>
                    <form className={classes.container} noValidate autoComplete="off">
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Customer App
                            </Typography>
                            <Card style={{padding: 16}}>
                                <h5>iOS</h5>
                                <span className={classes.flexBox}>
                                    <img
                                        src={toAbsoluteUrl(`/media/logos/appStore.png`)}
                                        alt={`AppStoreLogo`}
                                        className="mr-2"
                                        style={{
                                        width: '30px',
                                        height: '30px',
                                        marginTop: '2rem'
                                    }}/>
                                    <TextField
                                        id="outlined-name"
                                        label="App Store Link"
                                        name="appleStoreLink.customer"
                                        className={classes.textField}
                                        placeholder='Link Here'
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.appleStoreLink.customer}
                                        error={Boolean(errors.appleStoreLink && errors.appleStoreLink.customer)}/>
                                </span>
                                <Grid container spacing={2} style={{marginTop: 8}}>
                                    <Grid item xs={6}>
                                        <h6>Minimum Supported Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="iOSVersion.customer.minimumSupportedVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        value={values.iOSVersion.customer.minimumSupportedVersion}
                                        error={Boolean(errors.iOSVersion && errors.iOSVersion.customer && errors.iOSVersion.customer.minimumSupportedVersion)}/>
                            
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>Current Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="iOSVersion.customer.currentVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        value={values.iOSVersion.customer.currentVersion}
                                        error={Boolean(errors.iOSVersion && errors.iOSVersion.customer && errors.iOSVersion.customer.currentVersion)}/>
                            
                                    </Grid>
                                </Grid>
                            </Card>
                            <Card style={{padding: 16, marginTop: 16}}>
                                <h5>Android</h5>
                                <span className={classes.flexBox}>
                                    <img
                                        src={toAbsoluteUrl(`/media/logos/play-store.png`)}
                                        alt={`PlayStoreLogo`}
                                        className="mr-2"
                                        style={{
                                        width: '30px',
                                        height: '30px',
                                        marginTop: '2rem'
                                    }}/>
                                    <TextField
                                        id="outlined-name"
                                        label="Play Store Link"
                                        name="playStoreLink.customer"
                                        className={classes.textField}
                                        placeholder='Link Here'
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.playStoreLink.customer}
                                        error={Boolean(errors.playStoreLink && errors.playStoreLink.customer)}/>
                                </span>
                                <Grid container spacing={2} style={{marginTop: 8}}>
                                    <Grid item xs={6}>
                                        <h6>Minimum Supported Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="androidVersion.customer.minimumSupportedVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        value={values.androidVersion.customer.minimumSupportedVersion}
                                        error={Boolean(errors.androidVersion && errors.androidVersion.customer && errors.androidVersion.customer.minimumSupportedVersion)}/>
                            
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>Current Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="androidVersion.customer.currentVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        value={values.androidVersion.customer.currentVersion}
                                        error={Boolean(errors.androidVersion && errors.androidVersion.customer && errors.androidVersion.customer.currentVersion)}/>
                            
                                    </Grid>
                                </Grid>
                            </Card>
                            <Typography
                                className={`${classes.title} mt-5`}
                                color="textSecondary"
                                gutterBottom>
                                Professional App Links
                            </Typography>
                            <Card style={{padding: 16}}>
                                <h5>iOS</h5>
                                <span className={classes.flexBox}>
                                    <img
                                        src={toAbsoluteUrl(`/media/logos/appStore.png`)}
                                        alt={`AppStoreLogo`}
                                        className="mr-2"
                                        style={{
                                        width: '30px',
                                        height: '30px',
                                        marginTop: '2rem'
                                    }}/>
                                    <TextField
                                        label="App Store Link"
                                        name="appleStoreLink.vendor"
                                        className={classes.textField}
                                        placeholder='Link Here'
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.appleStoreLink.vendor}
                                        error={Boolean(errors.appleStoreLink && errors.appleStoreLink.vendor)}/>
                                </span>
                                <Grid container spacing={2} style={{marginTop: 8}}>
                                    <Grid item xs={6}>
                                        <h6>Minimum Supported Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="iOSVersion.professional.minimumSupportedVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        value={values.iOSVersion.professional.minimumSupportedVersion}
                                        error={Boolean(errors.iOSVersion && errors.iOSVersion.professional && errors.iOSVersion.professional.minimumSupportedVersion)}/>
                            
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>Current Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="iOSVersion.professional.currentVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        value={values.iOSVersion.professional.currentVersion}
                                        error={Boolean(errors.iOSVersion && errors.iOSVersion.professional && errors.iOSVersion.professional.currentVersion)}/>
                            
                                    </Grid>
                                </Grid>
                            </Card>
                            <Card style={{padding: 16, marginTop: 16}}>
                                <h5>Android</h5>
                                <span className={classes.flexBox}>
                                    <img
                                        src={toAbsoluteUrl(`/media/logos/play-store.png`)}
                                        alt={`PlayStoreLogo`}
                                        className="mr-2"
                                        style={{
                                        width: '30px',
                                        height: '30px',
                                        marginTop: '2rem'
                                    }}/>
                                    <TextField
                                        label="Play Store Link"
                                        name="playStoreLink.vendor"
                                        className={classes.textField}
                                        placeholder='Link Here'
                                        margin="normal"
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.playStoreLink.vendor}
                                        error={Boolean(errors.playStoreLink && errors.playStoreLink.vendor)}/>
                                </span>
                                <Grid container spacing={2} style={{marginTop: 8}}>
                                    <Grid item xs={6}>
                                        <h6>Minimum Supported Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="androidVersion.professional.minimumSupportedVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        value={values.androidVersion.professional.minimumSupportedVersion}
                                        error={Boolean(errors.androidVersion && errors.androidVersion.professional && errors.androidVersion.professional.minimumSupportedVersion)}/>
                            
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>Current Version</h6>
                                        <TextField
                                        id="outlined-name"
                                        name="androidVersion.professional.currentVersion"
                                        className={classes.textField}
                                        placeholder='Enter Version'
                                        variant="outlined"
                                        style={{margin: 0, marginLeft: 16}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        value={values.androidVersion.professional.currentVersion}
                                        error={Boolean(errors.androidVersion && errors.androidVersion.professional && errors.androidVersion.professional.currentVersion)}/>
                            
                                    </Grid>
                                </Grid>
                            </Card>
                            <Typography
                                className={`${classes.title} mt-5`}
                                color="textSecondary"
                                gutterBottom>
                                Other Links
                            </Typography>
                            <TextField
                                label="About Us"
                                name="aboutUs"
                                className={classes.textField}
                                placeholder='Link Here'
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.aboutUs}
                                error={Boolean(touched.aboutUs && errors.aboutUs)}/>
                            <TextField
                                label="Terms & conditions"
                                name="termsAndCondition"
                                className={classes.textField}
                                placeholder='Link Here'
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.termsAndCondition}
                                error={Boolean(touched.termsAndCondition && errors.termsAndCondition)}/>
                            <TextField
                                label="Privacy Settings"
                                name="privacyPolicy"
                                className={classes.textField}
                                placeholder='Link Here'
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.privacyPolicy}
                                error={Boolean(touched.privacyPolicy && errors.privacyPolicy)}/>
                            <TextField
                                label="Cancellation Policies"
                                name="cancellationPolicies"
                                className={classes.textField}
                                placeholder='Link Here'
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cancellationPolicies}
                                error={Boolean(touched.cancellationPolicies && errors.cancellationPolicies)}/>
                            <Typography
                                className={`${classes.title} mt-5`}
                                 color="textSecondary"
                                gutterBottom>
                                Support
                            </Typography>

                            <TextField
                                label="Contact No"
                                name="supportContactNo"
                                className={classes.textField}
                                placeholder='Enter the support contact number'
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.supportContactNo && errors.supportContactNo)}
                                value={values.supportContactNo}/>

                            <TextField
                                label="Email"
                                type="email"
                                name="supportEmail"
                                className={classes.textField}
                                placeholder='Enter the support email'
                                margin="normal"
                                variant="outlined"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.supportEmail && errors.supportEmail)}
                                value={values.supportEmail}/>

                            <Typography
                                className={`${classes.title} mt-5`}
                                 color="textSecondary"
                                gutterBottom>
                                Others
                            </Typography>

                            <TextField
                                label="GST Percentage"
                                name="gstPercentage"
                                className={classes.textField}
                                placeholder='Enter GST Percentage'
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.gstPercentage && errors.gstPercentage)}
                                value={values.gstPercentage}/>

                            <TextField
                                label="Convenience Fees"
                                name="convenienceFee"
                                className={classes.textField}
                                placeholder='Enter the convenience fees here'
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.convenienceFee && errors.convenienceFee)}
                                value={values.convenienceFee}/>

                            <Button
                                variant="contained"
                                color="primary"
                                className={`${classes.button} mt-3`}
                                onClick={handleSubmit}
                                disabled={loading}>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Save Changes</Button>

                        </CardContent>
                    </form>
                </Card>
            )}
        </Formik>
    );

};

export default GlobalSettings;