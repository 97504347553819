import React, {useState, useEffect, useRef} from 'react';
import {toast} from 'react-toastify';
import {connect} from "react-redux";
import {Form, Button, Modal, Image as BImage} from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import {injectIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../../_metronic/utils/utils";
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {addCategory} from '../../../crud/vendor-category.crud';
import * as vendorCategory from "../../../store/ducks/vendor-category.duck";
const CreateVendorCategory = (props) => {
    const {classes} = props;
    const [errors,
        setErrors] = useState({title: null, icon: null});
    const [selectedFile,
        setSelectedFile] = useState(null);
    const [loading,
        setLoading] = useState(false);
    const [title,
        setTitle] = useState('');
    const [preview,
        setPreview] = useState(null);
    const observed = useRef(null);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const onChangeHandler =  (event) => {
        let file = event.target.files[0];
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            console.log(img.width);
            console.log(img.height);
            if(img.width <= 120 && img.height <= 120 && img.width===img.height){
                console.log('true', true);
                    setSelectedFile(file);
                    setErrors({
                        ...errors,
                        icon: null
                    });
                return true;
            }
            setErrors({
                ...errors,
                icon: 'Icon should be 120x120px'
            });
            return false;
        }
    }

    const onTitleChangeHandler = (event) => {
        setTitle(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
    }

    const resetState = () => {
        setTitle('');
        setSelectedFile(undefined);
        setPreview(undefined);
        setErrors({
            title: null, 
            icon: null
        });
    }

    const validForm = () => {
        let e = true;

        if (!title && !selectedFile) {
            console.log(!title);
            setErrors({
                ...errors,
                title: 'Title is required',
                icon: 'Icon is required'
            });
            e = false;
        } else if (!title) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
            e = false;
        } else if (!selectedFile) {
            setErrors({
                ...errors,
                icon: 'Icon is required'
            });
            e = false;
        } else {
            setErrors({
                ...errors,
                title: null,
                icon: null
            });
        }
        return e;
    }

    const handleSubmit = () => {
        if (validForm()) {
            setLoading(true);
            addCategory(selectedFile, title).then((output) => {
                if (output.data.success) {
                    props.vendorCategoryAdded({skip: 0, limit: 5});
                    resetState();
                    setLoading(false);
                    toast.success('Vendor Category is successfully added!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    props.handleClose('professional-category');
                }
            }).catch((error) => {
                console.log('error', error);
                setLoading(false);
                
            });
        }
    };

    // const validateSize = (event) => {
    //     let file = event.target.files[0];
    //     let size = 1000000;
    //     let err = '';
    //     console.log(file.size);
    //     if (file.size > size) {
    //         err = file.type + 'is too large, please pick a smaller file\n';
    //         toast.error(err);
    //     }
    //     return true
    // };
   

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={() => {
                resetState();
                props.handleClose('professional-category')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Professional Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="professionalCategoryForm.icon">
                            <Form.Label>Icon</Form.Label>
                            <div className="row">
                                <div className="col-4">
                                    <div className={classes.bigAvatar}>
                                        {selectedFile
                                            ? (<BImage src={preview} thumbnail/>)
                                            : (
                                                <div
                                                    style={{
                                                    height: '100%',
                                                    width: '120px',
                                                    background: '#ccc',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    color: '#f6f6f6',
                                                    fontSize: '14px'
                                                }}>
                                                    <span>
                                                        120 x 120 px
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className={classes.profileImageUploaderContainer}>
                                        <div
                                            className={classes.uploadImageButtonFile}
                                            onClick={(e) => {
                                            observed
                                                .current
                                                .click();
                                        }}>
                                            <input
                                                ref={observed}
                                                type="file"
                                                style={{
                                                display: "none"
                                            }}
                                                onChange={onChangeHandler}/>
                                            <img src={toAbsoluteUrl("/media/icons/image-icon.png")} alt="no-img"/>
                                            <span>
                                                Browse Icon
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errors.icon
                                ? (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{errors.icon}</div>
                                    </div>
                                )
                                : null}

                        </Form.Group>
                        <Form.Group controlId="professionalCategoryForm.title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={onTitleChangeHandler}
                                isInvalid={!!errors.title}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                        resetState();
                        props.handleClose('professional-category');
                    }}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                        position: "relative"
                    }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {data: state};
};

const mapDispatchToProps = dispatch => {
    return {
        vendorCategoryAdded: ({skip, limit}) => dispatch(vendorCategory.actions.vendorCategoryAdded({skip, limit}))
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), {withTheme: true})(CreateVendorCategory)));
