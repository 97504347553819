import axios from "./axios.interceptor";
import api from "./api.util";

export const SUPPORT_URL = `${api}/account/admin/support`;
export const SUPPORT_LIST_URL = `${SUPPORT_URL}/list`;

export function listSupport({ skip, limit }) {
  return axios.get(`${SUPPORT_LIST_URL}?skip=${skip}&limit=${limit}`);
}

export function supportDetails(supportId) {
  return axios.get(`${SUPPORT_URL}/${supportId}`);
}

export function approveSupportDetails(supportId) {
  return axios.put(`${SUPPORT_URL}/${supportId}/approve`);
}

export function rejectSupportDetails(supportId) {
  return axios.put(`${SUPPORT_URL}/${supportId}/reject`);
}
