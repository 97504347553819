import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { addRole } from "../../../crud/role.crud";
import * as role from "../../../store/ducks/role.duck";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import ModulePermission from "../../common/ModulePermission";
import { Card } from "@material-ui/core";
import ModuleConfig from "./../../../config/module.config";
const CreateRole = (props) => {
  const { classes, intl } = props;
  const [errors, setErrors] = useState({ name: null });
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const [modules, setModules] = useState(ModuleConfig);

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
    } else {
      setErrors({
        ...errors,
        name: null,
      });
    }
  };

  const resetState = () => {
    setName("");
    setErrors({ name: null });
  };

  const validForm = () => {
    let e = true;

    if (!name) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        name: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      const payload = {
        name: name,
        permissions: Object.keys(modules)
          .filter((key) => modules[key].checked)
          .map((key) => {
            return {
              moduleKey: modules[key].key,
              moduleName: modules[key].name,
              role: modules[key].role,
            };
          }),
      };
      addRole(payload)
        .then((output) => {
          if (output.data.success) {
            props.roleAdded({ skip: 0, limit: 5 });
            resetState();
            setLoading(false);
            toast.success("Role is successfully added!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("role");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[error.response.data.errorCode],
                },
                { title: name }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={props.show}
        onClose={() => {
          resetState();
          props.handleClose("role");
        }}
      >
        <div className={classes.createRoleWrapper}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  resetState();
                  props.handleClose("role");
                }}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Create Role
              </Typography>
              <Button
                variant="primary"
                style={{
                  position: "relative",
                }}
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Save Changes
              </Button>
            </Toolbar>
          </AppBar>

          <Form className={classes.createRoleForm}>
            <Form.Group controlId="role.name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={onChangeNameHandler}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.dashboard}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.organizations}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.professionalCategories}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.professionals}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.languages}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.problemCategories}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.problems}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.forums}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.faqs}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.users}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.bookings}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.payments}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.supports}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.permissionCard}>
                  <ModulePermission
                    modules={modules}
                    module={modules.globalSetting}
                    setModules={setModules}
                  />
                </Card>
              </Grid>
            </Grid>
          </Form>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    roleAdded: ({ skip, limit }) =>
      dispatch(role.actions.roleAdded({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(CreateRole)
  )
);
