import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {toAbsoluteUrl} from "../../../../_metronic/utils/utils";
import {Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {bookingDetails} from './../../../crud/booking.crud';
import moment from 'moment';
import bookingConfig from  '././../../../config/booking';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'row'
    },
    categoryTag: {
        display: 'inline-block',
        marginLeft: '6px',
        padding: '4px 12px',
        background: 'rgb(88 103 221)',
        color: 'white',
        fontWeight: 500
    }
}));

const BookingDetails = () => {
    const classes = useStyles();
    const professionalRef = useRef(null);
    const [height, setHeight] = useState(0);
    const {bookingId} = useParams();
    const [bookingDetailsInfo, setBookingDetailsInfo] = useState();
    const renderGender = (selectedGender)=>{
        let genderText = 'N/A';
        switch(selectedGender){
            case 1: 
              genderText = 'Male';
              break;
            case 2: 
              genderText = 'Female';
              break;
            case 3: 
              genderText = 'Other';
              break;
            default: 
              break;
        }
        return genderText;
    };

    const renderSlots = (slots)=>{
        return (<p>
            {slots.map((eachSlot, key)=>{
                if((slots.length-1)===key){
                    return bookingConfig.slots[eachSlot];
                }else{
                    return bookingConfig.slots[eachSlot] + ', ';
                }
            })}
        </p>);
    };

    useEffect(() => {
        bookingDetails(bookingId).then((output) => {
            setBookingDetailsInfo({
                ...bookingDetailsInfo,
                ...output.data.data
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if(professionalRef.current){
            setHeight(professionalRef.current.clientHeight);
        }
    });

    if(!bookingDetailsInfo){
        return null;
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={2} >
                    <Grid item xs={6} >
                        <Paper className={classes.paper} style={{height: height}}>
                            <div className={classes.flexBox}>
                                <div className="mr-3">
                                    <img
                                        src={bookingDetailsInfo.customerRef.personalInfo.profilePicture? bookingDetailsInfo.customerRef.personalInfo.profilePicture: toAbsoluteUrl(`/media/default-image/default-image.png`)}
                                        alt={`CustomersImage`}
                                        style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%'
                                    }}/>
                                </div>
                                <div className="text-left mt-1">
                                    <h5>{bookingDetailsInfo.customerRef.personalInfo.name}</h5>
                                    <span
                                        style={{
                                        display: 'block',
                                        marginBottom: '5px'
                                    }}>
                                        <span>Contact no -&nbsp;</span>
                                        <span>{bookingDetailsInfo.customerRef.personalInfo.phone.dialCode} {bookingDetailsInfo.customerRef.personalInfo.phone.number}</span>
                                    </span>
                                    <span
                                        style={{
                                        display: 'block',
                                        marginBottom: '5px'
                                    }}>
                                        <span>Email -&nbsp;</span>
                                        <span>{bookingDetailsInfo.customerRef.personalInfo.email}</span>
                                    </span>
                                    <span
                                        style={{
                                        display: 'block'
                                    }}>
                                        <span>Gender -&nbsp;</span>
                                        <span>{renderGender(bookingDetailsInfo.customerRef.personalInfo.gender)}</span>
                                    </span>

                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className={classes.paper} ref={professionalRef}>
                            <div className={classes.flexBox}>
                                <div className="mr-3">
                                    <img
                                        src={bookingDetailsInfo.vendorRef.personalInfo.profilePicture? bookingDetailsInfo.vendorRef.personalInfo.profilePicture: toAbsoluteUrl(`/media/default-image/default-image.png`)}
                                        alt={`CustomersImage`}
                                        style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%'
                                    }}/>
                                </div>
                                <div className="text-left mt-1">
                                    <h5>{bookingDetailsInfo.vendorRef.personalInfo.name}</h5>
                                    <span
                                        style={{
                                        display: 'block',
                                        marginBottom: '5px'
                                    }}>
                                        <span>Contact no -&nbsp;</span>
                                        <span>{bookingDetailsInfo.vendorRef.personalInfo.phone.dialCode}{bookingDetailsInfo.vendorRef.personalInfo.phone.number}</span>
                                    </span>
                                    <span
                                        style={{
                                        display: 'block',
                                        marginBottom: '5px'
                                    }}>
                                        <span>Email -&nbsp;</span>
                                        <span>{bookingDetailsInfo.vendorRef.personalInfo.email}</span>
                                    </span>
                                    <span
                                        style={{
                                        display: 'block'
                                    }}>
                                        <span>Experience -&nbsp;</span>
                                        <span>{bookingDetailsInfo.vendorRef.personalInfo.experience.year} years</span> &nbsp;
                                        {bookingDetailsInfo.vendorRef.personalInfo.experience.month ? <span>{bookingDetailsInfo.vendorRef.personalInfo.experience.month} months</span>: null}
                                    </span>
                                    <div>
                                        <span>Categories -&nbsp;</span>
                                        <span>{bookingDetailsInfo.vendorRef.personalInfo.categories.map((eachCat, key)=>{
                                             return (<span key={key} className={classes.categoryTag}>
                                                 {eachCat.title}
                                             </span>)                                                        
                                        })}</span>
                                    </div>
                                    <div>
                                        <span>Website -&nbsp;</span>
                                        <span>{bookingDetailsInfo.vendorRef.personalInfo.website? bookingDetailsInfo.vendorRef.personalInfo.website: 'N/A'}</span>
                                    </div>
                                    <div>
                                        <span>Description -&nbsp;</span>
                                        <span>{bookingDetailsInfo.vendorRef.personalInfo.description? bookingDetailsInfo.vendorRef.personalInfo.description: 'N/A'}</span>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={`${classes.paper} text-left`}>
                            <h4 className="mb-3">Service</h4>
                            
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Service Fees</th>
                                        <th>GST</th>
                                        <th>Convenience Fees</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bookingDetailsInfo.serviceRef.title}</td>
                                        <td>{bookingDetailsInfo.serviceRef.description}</td>
                                        <td>{bookingDetailsInfo.date? moment(bookingDetailsInfo.date).format('DD-MMM-YYYY'):'N/A'}</td>
                                        <td>
                                            {renderSlots(bookingDetailsInfo.slots)}
                                        </td>
                                        <td>{bookingDetailsInfo.paymentDetails.serviceFee}</td>
                                        <td>{bookingDetailsInfo.paymentDetails.gst}</td>
                                        <td>{bookingDetailsInfo.paymentDetails.convenienceFee}</td>
                                        <td>{bookingDetailsInfo.paymentDetails.total}</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </Paper>
                    </Grid>
                </Grid>
            </div>

        </React.Fragment>
    );

};

export default BookingDetails;