import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button, Modal, Image as BImage } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { editOrganization } from "../../../crud/organization.crud";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import * as organization from "../../../store/ducks/organization.duck";
const EditOrganization = (props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: null,
    profilePicture: null,
    email: null,
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const observed = useRef();

  const { classes, organization, intl } = props;

  useEffect(() => {
    const dataProps = JSON.parse(JSON.stringify(organization));
    dataProps.personalInfo.profilePicture &&
      setPreview(dataProps.personalInfo.profilePicture);
    setName(dataProps.personalInfo.name);
    setEmail(dataProps.personalInfo.email);
    setOldEmail(dataProps.personalInfo.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
    } else {
      setErrors({
        ...errors,
        name: null,
      });
    }
  };

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
    } else {
      setErrors({
        ...errors,
        email: null,
      });
    }
  };

  const resetState = () => {
    setName("");
    setEmail("");
    setSelectedFile(undefined);
    setPreview(undefined);
    setErrors({
      name: null,
      profilePicture: null,
      email: null,
    });
  };

  const onChangeHandler = (event) => {
    let file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      console.log(img.width);
      console.log(img.height);
      if (img.width <= 600 && img.height <= 600) {
        console.log("true", true);
        setSelectedFile(file);
        setErrors({
          ...errors,
          icon: null,
        });
        return true;
      }
      setErrors({
        ...errors,
        icon: "Icon should be 600x600px",
      });
      return false;
    };
  };

  const validForm = () => {
    let e = true;

    if (!name && !email) {
      setErrors({
        ...errors,
        name: "Name is required",
        email: "Profile Picture is required",
      });
      e = false;
    } else if (!name) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
      e = false;
    } else if (!email) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        name: null,
        email: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      const payload = {
        name: name,
      };

      if (oldEmail !== email) {
        payload.email = email;
      }

      if (selectedFile) {
        payload.profilePicture = selectedFile;
      }

      editOrganization(organization._id, payload)
        .then((output) => {
          if (output.data.success) {
            props.organizationUpdated({ skip: 0, limit: 5 });
            resetState();
            setLoading(false);
            toast.success("Organization is successfully updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("organization");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[error.response.data.errorCode],
                },
                { title: name }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={() => props.handleClose("organization")}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="organizationForm.profilePicture">
              <Form.Label>Profile Picture</Form.Label>
              <div className="row">
                <div className="col-5">
                  <div className={classes.bigAvatar}>
                    {preview ? (
                      <BImage src={preview} thumbnail />
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          width: "120px",
                          background: "#ccc",
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          justifyContent: "center",
                          color: "#f6f6f6",
                          fontSize: "14px",
                        }}
                      >
                        <span>600 x 600 px</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-5">
                  <div className={classes.profileImageUploaderContainer}>
                    <div
                      className={classes.uploadImageButtonFile}
                      onClick={(e) => {
                        observed.current.click();
                      }}
                    >
                      <input
                        ref={observed}
                        type="file"
                        style={{
                          display: "none",
                        }}
                        onChange={onChangeHandler}
                      />
                      <img
                        src={toAbsoluteUrl("/media/icons/image-icon.png")}
                        alt="no-img"
                      />
                      <span>Browse Profile Picture</span>
                    </div>
                  </div>
                </div>
              </div>
              {errors.profilePicture ? (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{errors.profilePicture}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="organizationForm.name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={onChangeNameHandler}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="organization.name">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={onChangeEmailHandler}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.handleClose("organization")}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    organizationUpdated: ({ skip, limit }) =>
      dispatch(organization.actions.organizationUpdated({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(EditOrganization)
  )
);
